import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/56c857c7/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Gatsby themes allow you to override configuration from the theme by defining the same property in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` at the root of your project. You can see the default configuration provided by the theme’s `}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/master/packages/gatsby-theme-carbon/gatsby-config.js"
        }}><inlineCode parentName="a">{`gatsby-config.js`}</inlineCode></a>{` file.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Site Title and Description</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Manifest</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Favicon</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Additional font weights</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Image Compression</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Global Search</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Edit on Github link</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "site-title-and-description"
    }}>{`Site Title and Description`}</h2>
    <p>{`To add a title and description to each page, simply provide them to siteMetadata in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  siteMetadata: {
    title: 'Gatsby Theme Carbon',
    description: 'A Gatsby theme for the carbon design system',
    keywords: 'gatsby,theme,carbon',
  },
  plugins: ['gatsby-theme-carbon'],
};
`}</code></pre>
    <h2 {...{
      "id": "manifest"
    }}>{`Manifest`}</h2>
    <p>{`One of the first configurations should be to override the default manifest options, you can do this in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`. Any options you don’t set, will be provided by the theme. See the example project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`siteMetadata: {
    title: 'Gatsby Theme Carbon',
  },
  plugins: [
    'gatsby-theme-carbon',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Carbon Design Gatsby Theme',
        short_name: 'Gatsby Theme Carbon',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#0062ff',
        display: 'browser',
      },
    },
  ],
`}</code></pre>
    <h2 {...{
      "id": "favicon"
    }}>{`Favicon`}</h2>
    <p>{`If you need to override the default favicon, you can do so by passing a relative path to the icon. It’s recommended to provide a 512 x 512 version.`}</p>
    <p><strong parentName="p">{`IMPORTANT:`}</strong>{` For best results, if you’re providing an icon for generation it should be…`}</p>
    <ul>
      <li parentName="ul">{`at least as big as the largest icon being generated (512x512 by default).`}</li>
      <li parentName="ul">{`square (if it’s not, transparent bars will add to make it square).`}</li>
      <li parentName="ul">{`of one of the follow formats: JPEG, PNG, WebP, TIFF, GIF or SVG.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        iconPath: './src/images/custom-icon.png'
      },
    },
  ],
`}</code></pre>
    <h2 {...{
      "id": "additional-font-weights"
    }}>{`Additional font weights`}</h2>
    <p>{`If needed, you can add support for additional Plex font weights. Don’t forget to specify italics for the additional weights if needed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        // will get added to default [300, 400, 600]
        additionalFontWeights: ['200', '200i]
      },
    },
  ],
`}</code></pre>
    <h2 {...{
      "id": "image-compression"
    }}>{`Image Compression`}</h2>
    <p>{`You can enable WebP by passing `}<inlineCode parentName="p">{`withWebp: true`}</inlineCode>{` or providing your own optimization level. See the gatsby-remark-images `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-remark-images/#options"
      }}>{`plugin options`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  siteMetadata: {
    title: 'Gatsby Theme Carbon',
  },
  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        name: 'Gatsby Theme Carbon Starter',
        shortName: 'Carbon Starter',
        withWebp: true,
      },
    },
  ],
};
`}</code></pre>
    <h2 {...{
      "id": "global-search"
    }}>{`Global Search`}</h2>
    <p>{`The GlobalSearch component is disabled by default. If you’d like to implement search functionality, you’ll need to follow these two steps:`}</p>
    <ol>
      <li parentName="ol">{`set the isSearchEnabled theme option to true`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        isSearchEnabled: true
      },
    },
  ],
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Shadow the example search implementation at `}<inlineCode parentName="li">{`/src/util/hooks/useSearch.js`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useEffect } from 'react';
const useAlgoliaSearch = () => {
  // ...
};

export default useAlgoliaSearch;
`}</code></pre>
    <p>{`The example `}<inlineCode parentName="p">{`useSearch`}</inlineCode>{` hook demonstrates implementing search with `}<a parentName="p" {...{
        "href": "https://www.algolia.com/"
      }}>{`Algolia`}</a>{`. Algolia is free for open source libraries. You can shadow this hook and replace it with your Algolia credentials or a library of your choosing.`}</p>
    <h2 {...{
      "id": "edit-on-github-link"
    }}>{`Edit on GitHub link`}</h2>
    <p>{`To add a link to the bottom of each page that points to the current page source in GitHub, provide an `}<inlineCode parentName="p">{`repository`}</inlineCode>{` object to `}<inlineCode parentName="p">{`siteMetadata`}</inlineCode>{` in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file. You can provide a `}<inlineCode parentName="p">{`baseUrl`}</inlineCode>{`, and if needed, the `}<inlineCode parentName="p">{`subDirectory`}</inlineCode>{` where your site source lives.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        repository: {
          baseUrl: 'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
        },
      },
    },
  ],
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      